import * as React from "react";

import { cn } from "@/lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  pencil?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-lg ring-2 ring-brown shadow-md shadow-white bg-white px-3 py-2 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-orangeLight",
          // "border-slate-200 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
          "font-main",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

const GhostInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, pencil, ...props }, ref) => {
    return (
      <label className={cn("relative inline-block w-full", className)}>
        <input
          type={type}
          className="flex h-8 w-full rounded-lg bg-white px-2 py-1 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orangeLight font-main peer"
          ref={ref}
          {...props}
        />
        <FontAwesomeIcon
          icon={faPencil}
          className={cn(
            !pencil && "hidden",
            "absolute top-0 right-0  translate-y-2/4 -translate-x-2/4 text-brown/60 peer-focus-visible:hidden",
          )}
        />
      </label>
    );
  },
);
GhostInput.displayName = "Input";

export { Input, GhostInput };
