import { GameStore } from "@/storage";
import { UserRes } from "./user";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { apiFetch } from "./utils";

export enum GameType {
  Tally = "Tally",
  Scorecard = "Scorecard",
  Tournament = "Tournament",
}
export type TeamRes = {
  id: string;
  users: UserRes[];
};
export type GroupRes = {
  id: string;
  permission_level: number;
  teams: TeamRes[];
};
export type GameRes = {
  id: string;
  name: string;
  type: string;
  permission_level: number;
  groups: GroupRes[];
};

export async function createGame({
  request,
}: ActionFunctionArgs): Promise<Response> {
  let state = await request.json();
  const response = await apiFetch("POST", "/games", state);
  if (response.ok) {
    let id = (await response.json()).id;
    new GameStore().set(id, state);
    let type = state.type;
    switch (type) {
      case GameType.Tally:
        return redirect(`/games/${id}/groups/create`);
      case GameType.Scorecard:
        return redirect(`/games/${id}/scorecard-template`);
    }
  }
  throw Error("Error creating game.");
}

export async function createGroup(
  gameID: string,
  teams: string[][],
): Promise<string> {
  const teamsReq = teams.map((l) => ({ ids: l }));
  const response = await apiFetch("POST", `/games/${gameID}/groups`, {
    teams: teamsReq,
  });

  if (response.ok) return (await response.json()).id;
  else throw Error("Error creating group.");
}

export async function deleteGroup({
  params,
}: ActionFunctionArgs): Promise<Response> {
  const groupID = params.groupID || "";

  const response = await apiFetch("DELETE", `/groups/${groupID}`);

  if (response.ok) return redirect("/home");
  else throw response;
}

export async function getGames(): Promise<GameRes[]> {
  const response = await apiFetch("GET", `/games`);

  if (response.ok) return await response.json();
  else throw Error("Error loading games.");
}
