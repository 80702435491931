import { TallyGroupRes } from "@/api/tally";
import { AvatarTeamLg } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  faMinus,
  faPlus,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams, useRouteLoaderData, useSubmit } from "react-router-dom";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import NavBar from "@/components/ui/nav_bar";
import { Drawer, DrawerTrigger } from "@/components/ui/drawer";
import ToolsList from "../tools/menu";
import { GameStore } from "@/storage";
import { TeamRes } from "@/api/game";

function Score({
  team,
  init_score,
  update,
}: {
  team: TeamRes;
  init_score: number;
  update: (n: number) => void;
}) {
  const [score, scoreSetter] = useState(init_score);
  useEffect(() => {
    update(score);
  }, [score]);

  return (
    <Card className="relative">
      <CardHeader className="justify-center">
        <AvatarTeamLg users={team.users} />
      </CardHeader>
      <CardContent className="p-4">
        <div className="flex gap-2 items-stretch">
          <div>
            <Input
              type="number"
              className="h-full text-center text-3xl"
              value={score}
              onChange={(e) => scoreSetter(parseInt(e.currentTarget.value))}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Button
              type="button"
              variant="iconAlt"
              size="iconAlt"
              onClick={() => scoreSetter(score + 1)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              type="button"
              variant="iconAlt"
              size="iconAlt"
              onClick={() => scoreSetter(score - 1)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function TallyEdit() {
  const navSubmit = useSubmit();

  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";
  const trackerID = params.trackerID || "";

  const gameName = new GameStore().get(gameID)?.name;
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;
  const loaderTally = loaderData.tallies.find((t) => t.id == trackerID);
  if (!loaderTally) throw Error("Tally not found.");
  const loaderScores = loaderTally.scores;

  const teamsMap = Object.assign(
    {},
    ...loaderData.group.teams.map((t) => ({ [t.id]: t })),
  );
  const [scores, scoresSetter] = useState<number[]>(
    loaderScores.map((t) => t.score),
  );

  function update(i: number, score: number) {
    let newScores = [...scores];
    newScores[i] = score;
    scoresSetter(newScores);
  }

  function submit(e: React.FormEvent) {
    e.preventDefault();

    const payload = loaderScores.map((t, i) => ({
      team: t.team_id,
      score: scores[i],
    }));

    navSubmit(
      { scores: payload },
      { method: "put", encType: "application/json" },
    );
  }

  return (
    <>
      <NavBar back={`/games/${gameID}/groups/${groupID}/trackers`} logo>
        <Drawer>
          <DrawerTrigger asChild>
            <Button variant="icon" size="icon">
              <FontAwesomeIcon icon={faScrewdriverWrench} />
            </Button>
          </DrawerTrigger>
          <ToolsList teams={loaderData.group.teams} />
        </Drawer>
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">{gameName}</h2>

        <form>
          <div className="grid auto-rows-fr grid-cols-2 gap-x-4 gap-y-6">
            {loaderScores.map((t, i) => (
              <div key={i} className="relative">
                {/*
                {loaderScores.length > 2
                  ? i > 0 &&
                    i % 2 == 0 && (
                      <Versus
                        size="lg"
                        className="absolute z-20 top-0 right-0 translate-x-10 -translate-y-10"
                      />
                    )
                  : i == 0 && (
                      <Versus
                        size="lg"
                        className="absolute z-20 bottom-0 right-0 translate-x-10 translate-y-10"
                      />
                    )}
                */}
                <Score
                  team={teamsMap[t.team_id]}
                  init_score={scores[i]}
                  update={(n) => update(i, n)}
                />
              </div>
            ))}
          </div>
        </form>
        <div className="flex justify-center">
          <Button size="lg" onClick={submit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
